@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

* {
  margin: 0% !important;
  font-family: "Poppins", sans-serif !important;
}



body {
  background: #fff;
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


a {
  text-decoration: none !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

p {
  color: #000;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cardTask {
  height: auto !important;
  width: 650px !important;
  text-align: center;
  color: #006279;
  border-radius: 10px;
  border: 1px solid #ccc;
  background: #fff;
  float: left;
  padding: 0%;
  font-size: 20px;
  transition: box-shadow 0.3s;
  margin-left: 20px !important;
}

.containerModules {
  justify-content: space-evenly;
}

.heroPage {
  padding: 0%;
  padding-top: 5%;
  border-radius: 0%;
  text-align: center;
  background-color: #00608e;
  background-image: linear-gradient(0deg, #45bcf6 0%, #00608e 100%);
}

.centerText {
  text-align: center;
  color: #fff;
  font-size: 100px;
  font-weight: 600;
}

/*Data form section*/

.inputsData {
  align-self: center;
}

.iconContactForm {
  align-self: center;
  margin-right: 5px !important;

  width: 35px;
  height: 35px;
}

.scrollbar {
  overflow: scroll;
  width: auto;
  height: 230px !important;
}



.background_login {
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(assets/005_BACGROUND_LOGIN.png);
}



.background_reset {
  min-height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(assets/011_background_reset_password.jpg);
}



.login-section {
  position: relative;
  width: 500px;
  top: 100px;
  left: 90px;
}

.login-card {
  background-color: #fff;
  border-radius: 20px;

  -webkit-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
}

.glass-welcome-container {
  /* From https://css.glass */
  background: rgba(22, 22, 22, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(22, 22, 22, 0.05);


  height: 200px;
  position: relative;
  top: 200px;
  padding: 20px;

}

.background-signup {
  min-height: 100vh;
  background-image: url(assets/006_background_login.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -200px;
}

.background-admin {
  min-height: 100vh;
  background-image: url(assets/008_background-admin.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -200px;
}

.background-addtags {
  min-height: 100vh;
  background-image: url(assets/009_background_addtask.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -200px;
}

.background-new-tag {
  min-height: 100vh;
  background-image: url(assets/007_background_new_tag.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0px -200px;
}


.file-uploader-input {
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  height: 50px;
  display: flex;
  align-content: center;
  align-items: center;
  padding: 10px;
  border-style: dashed;
}


/*table*/

.shadow-table {

  padding: 15px;
  height: 400px;

  background: linear-gradient(229.04deg, #FFFFFF 6.06%, #F1F1F1 100%);
  -webkit-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  -moz-box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  box-shadow: 0px 10px 35px -18px rgba(0, 0, 0, 0.59);
  border-radius: 30px;
}

.button-action-card-1 {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 20px !important;
  background: linear-gradient(180deg, #6AC1CD 0%, #08ACA2 100%);
  box-shadow: 0px 20px 20px rgba(40, 194, 227, 0.4);
}

.button-action-card-2 {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 20px !important;
  background: linear-gradient(180deg, #FF8A00 0%, #FFB800 100%);
  box-shadow: 0px 20px 20px rgba(255, 138, 0, 0.25);

}

.button-action-card-3 {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 20px !important;
  background: linear-gradient(180deg, #AB1D48 0%, #E90046 100%);
  box-shadow: 0px 20px 20px rgba(171, 29, 72, 0.25);
}

.button-action-card-4 {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 20px !important;
  background: linear-gradient(180deg, #04F182 0%, #04DBF1 100%);
  box-shadow: 0px 20px 20px rgba(141, 141, 141, 0.25);
}

.button-action-card-5 {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 20px !important;
  background: linear-gradient(180deg, #F3F3F3 0%, #FFFFFF 100%);
  box-shadow: 0px 20px 20px rgba(141, 141, 141, 0.25);
}


.button-action-card-6 {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 20px !important;
  background: linear-gradient(180deg, #1A6BE7 0%, #252523 100%);
  box-shadow: 0px 20px 20px rgba(0, 58, 248, 0.25);
}

.button-action-card-7 {
  border-radius: 20px;
  width: 200px;
  height: 200px;
  margin: 20px !important;
  background: linear-gradient(180deg, #2DFF6D 0%, #20D100 100%);
  box-shadow: 0px 20px 20px rgba(102, 255, 1, 0.25);

}

.iconActionCard {
  width: 180px;
  position: relative;
  left: -14px;
  top: -10px;
}


.title-card-action {
  color: #fff;
  font-size: 20px;
  font-weight: 500;
}

.icon-container-card-1 {
  height: 150px;
  background-position: center;
  background-size: 150px;
  background-repeat: no-repeat;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/iconos-new%2FMASCOTAS.svg?alt=media&token=5aae874b-6722-4859-b864-0fdec059dd39");
}

.icon-container-card-2 {
  height: 150px;
  background-position: center;
  background-size: 150px;
  background-repeat: no-repeat;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/iconos-new%2FPERFIL.svg?alt=media&token=83f23e2f-06c6-4eca-a2b0-fad27cd46537");
}

.icon-container-card-3 {
  height: 150px;
  background-position: center;
  background-size: 150px;
  background-repeat: no-repeat;
  background-image: url(assets/icons/010_qr_icon.png);
}

.icon-container-card-4 {
  height: 120px;
  background-position: center;
  background-size: 120px;
  background-size: contain;
  margin-top: 5% !important;
  background-repeat: no-repeat;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/iconos-new%2FTAG.svg?alt=media&token=00ec58c7-0b74-4510-9d25-8dafb3932e40");
}

.icon-container-card-6 {
  height: 120px;
  background-position: center;
  background-size: 120px;
  background-size: contain;
  margin-top: 5% !important;
  background-repeat: no-repeat;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/iconos-new%2FSOPORTE.svg?alt=media&token=b305147d-2581-4ef2-9826-d7a327a59ed8");
}

.icon-container-card-7 {
  height: 120px;
  background-position: center;
  background-size: 120px;
  background-size: contain;
  margin-top: 5% !important;
  background-repeat: no-repeat;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/iconos-new%2FWHATS.svg?alt=media&token=015573c0-998d-47bf-bd7f-67f86b64c629");
}

/* Pet */

.pet-circle-image {
  width: 220px;
  height: 220px;
  border-radius: 50% !important;
  align-self: center;
  object-fit: cover;
  object-position: center;
  background-color: #fff;
  border: 8px solid rgb(255, 221, 0);
  /* shadow yellow */
  box-shadow: 0px 10px 10px rgba(248, 187, 17, 0.25);

}

/* responsive pet-circle-image */
@media screen and (max-width: 600px) {
  .pet-circle-image {
    width: 200px;
    height: 200px;
  }
}

.pet-circle-image-details {
  border-radius: 50%;
  width: 120px;
  height: 120px;
  background-position: center;
  align-self: center;
  background-size: cover;
}

.pet-circle-name {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  align-self: center;
}


/* Tags */


.badge-info {
  width: 400px;
  background: linear-gradient(269.63deg, #FFE600 0.22%, #FF8A00 99.68%);
  border-radius: 20px;
  text-align: center;
  margin: 20px !important;

}

.badge-info-blue {
  background: linear-gradient(90.1deg, #0066FF 0.06%, #00FFF0 99.91%);
  border: 1px solid #EBEBEB;
  height: 80px;
  align-content: center !important;

  box-sizing: border-box;
  box-shadow: 0px 10px 10px rgba(139, 141, 141, 0.25);
  text-align: center;
  border-radius: 20px;
  align-self: center;
  margin: 20px !important;
}

.input-file {
  width: 300px;
  align-self: center;
  margin: 20px !important;
}

.badge-title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 75px;
  /* identical to box height */
  color: #FFFFFF;
}


.shadow {
  border-radius: 20px;
  -webkit-box-shadow: 0px 10px 46px -15px rgba(0, 0, 0, 0.39);
  -moz-box-shadow: 0px 10px 46px -15px rgba(0, 0, 0, 0.39);
  box-shadow: 0px 10px 46px -15px rgba(0, 0, 0, 0.39);
}

.info-text-container {
  border: 1px solid #C6C6C6;
  border-radius: 20px;
  padding: 20px;
  width: 400px;
}

.reward-text-container {
  border: 1px solid #32FF53;
  background-color: #32FF53;
  border-radius: 20px;
  padding: 20px;

}

.button-card-info-pet {
  color: #fff;
  width: 200px;
  font-size: 20px;
  margin: 10px !important;
  border: 0px !important;
  background: linear-gradient(269.63deg, #FFE600 0.22%, #FF8A00 99.68%);
  border-radius: 20px;
}

.rotateImage {
  position: relative;
  width: 100px;
  top: -200px;
  transform: rotate(90deg);
}

.btn-not-found {
  position: relative;
  top: -400px;
}

.btnAddIndividualTag {
  background-color: #e98400;
  margin: 20px !important;
}




/* terminos */

.bodybackground {
  background-color: #F7F7F7;
}

.parraf {

  margin-top: 2% !important;
  margin-bottom: 2% !important;
  color: #000;
  font-size: 18px;
  text-align: justify !important;
}

.main-container {
  width: 100%;
  padding: 5%;
}

.text-center {
  color: #000;
}

/* Responsive */



#row-mb {
  display: none;
}

@media screen and (max-width: 1000px) {

  .w-50-responsive {
    width: 100% !important;
  }

  .bg-paw-dog {
    width: 100% !important;
  }


  .paw-monthly,
  .paw-yearly {
    width: 100% !important;
    height: 130px !important;
  }

  .mw-50-responsive {
    width: 80% !important;
  }

  #row-pc {
    display: none !important;
  }

  #row-mb {
    display: block;
  }

  .badge-info {
    width: 300px;
  }

  .badge-info-blue {
    width: 300px;
  }
}


/* Updates  sept 2022 */



.col-container {
  border: 1px solid rgb(189, 189, 189);
  border-radius: 20px;
}

.divider {
  border-bottom: 2px solid rgb(207, 207, 207);
}

.logo_container_nav_color {
  width: 300px;
  height: 200px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/favicons%2FLOGO-png.png?alt=media&token=a2ca2a5e-38f6-4e70-b776-e2d19993712b")
}


/* ***** Inicio redesign ***** */

.container-dog-face {
  background: url("https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/rounded-doc-face-compressed.png?alt=media&token=e4a82a56-e5b0-475f-832a-a3a915586e0c");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 400px;
  width: 400px;
}


.container-qr-demo {

  background: url("https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/qrcode_idpetsqr.com.png?alt=media&token=d6e49454-abe9-4100-966c-ac48361ac04d");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  height: 300px;
  width: 300px;
}

.circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
}

/* ***** Ends Inicio redesign ***** */

/* *** Nuevo tag  *** */
.new-tag-bg {
  background: url("https://firebasestorage.googleapis.com/v0/b/desarrollalab-ws.appspot.com/o/blob-scene-haikei%20(3).svg?alt=media&token=ae383f57-373d-4785-a81a-4d560eaaa042");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


.happy-dog-img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  background: url("https://img.freepik.com/foto-gratis/escuchandote-perro-joven-bulldog-frances-planteando-lindo-perrito-o-mascota-blanco-negro-jugueton-jugando-parece-feliz-aislado-sobre-fondo-blanco-concepto-movimiento-accion-movimiento_155003-34897.jpg?w=740&t=st=1669237710~exp=1669238310~hmac=e0238714edaa62e6c937b910b5a1937d9f42f826adb3e83a865c679fd1f63906");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* *** Ends Nuevo tag *** */




/* Wabutton */


.wp-button {
  margin-top: 14px;
}

.float {
  position: fixed;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/idpets.appspot.com/o/icons8-whatsapp%201.svg?alt=media&token=74a1a8ab-8794-4f4a-8a2f-ce7cb05b0731");
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #25d366;
  color: #FFF;
  border-radius: 50px;
  padding: 0% !important;
  text-align: center;
  font-size: 35px;
  z-index: 100;
}

.float:hover {
  background-color: #009838 !important;
  border: 0px;
  color: #25d366 !important;
  border: 1px solid #25d366;
  transition: 0.3s;
}

/* Ends wa button */



/* help button */


.hp-button {
  margin-top: 14px;
}

.hp-float {
  position: fixed;

  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fhelp1-svgrepo-com.svg?alt=media&token=6d39b741-8e6c-4a96-b245-37f20346260d");
  width: 60px;
  height: 60px;
  bottom: 40px;
  right: 40px;
  background-color: #000;
  color: #FFF;
  border-radius: 50px;
  padding: 0% !important;
  text-align: center;
  font-size: 35px;
  z-index: 100;
}

.hp-float:hover {
  background-color: #fff !important;
  border: 0px;
  color: #d3aa25 !important;
  border: 1px solid #d3aa25;
  transition: 0.3s;
}

/* Ends help button */

/* Messages client */
.container-full-space {
  animation: fadeInAnimation ease 100ms;
  height: 100vh;
  width: 100vw;
  background-color: #fff;
  position: fixed;
  padding: 10px;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.response-admin {
  background-color: rgb(255, 227, 133) !important;
  border-radius: 10px !important;
}

.response-client {
  background-color: rgb(247, 247, 247) !important;
  border-radius: 10px !important;
}

/* End Messages client */

/* Info page */

.icon-info-circle {
  align-self: center;
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  width: 50px;
  height: 50px;
  cursor: pointer;
}



.accordion-button:not(.collapsed)::after {
  background-image: url("https://www.pngkey.com/png/full/11-117105_down-arrow-png-image-background-arrow-down-icon.png");
  transform: rotate(-180deg);
}

.accordion-button:not(.collapsed) {
  color: #000;
  background: rgb(255, 209, 0);
  background: linear-gradient(90deg, rgba(255, 209, 0, 1) 0%, rgba(255, 244, 0, 1) 35%, rgba(255, 222, 0, 1) 100%);
  box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

.accordion-button {
  background: rgb(255, 209, 0);
  background: linear-gradient(270deg, rgba(255, 209, 0, 1) 0%, rgba(255, 244, 0, 1) 56%, rgba(255, 222, 0, 1) 100%);
}


/* Pulse effect */
.green {
  background: #66ff99;
}

.orange {
  background: #f17c57fa;
}

.blue {
  background: #3652D9;
}

.rose {
  background: #FF66CC;
}

.center {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 100vh;
}

.circle {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px #0000001a;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}


.master {
  color: black;
  float: right;
}

@media (max-width:900px) {
  .master {
    display: none
  }
}

/* Ends Pulse effect */


/* Ends Info page */


/* CUSTOM 2 */

.h-70 {
  height: 70vh !important;
}


.bg-yellow-poly {
  background-image: url('https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Flow-poly-grid-haikei%20(3).svg?alt=media&token=a92982e9-b92a-402d-a454-78c96569c533');
}

.bannerPro {
  background-position: center;
  background-size: cover;
  background-image: url('https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Flow-poly-grid-haikei%20(4).svg?alt=media&token=d50ea947-3868-4c67-866e-c1dae7cfca6a');
}

.rounded-2xl {
  border-radius: 20px !important;
}


.bg-gray-50 {
  background-color: #F8F8F8;
}

.align-center {
  display: flex;
  justify-content: center;
  align-items: center !important;
}

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  z-index: 100;
}

/* WRAPPERS */
#wrapper {
  width: 100%;
  overflow-x: hidden;
  display: -ms-flex;
  display: -webkit-flex;
  display: flex;
}

.wrapper {
  padding: 0 20px;
}

.wrapper-content {
  padding: 0px;
}

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}


.h-100 {
  height: 90vh !important;
}

@media screen and (max-width:1000px) {
  .h-100 {
    height: auto !important;
    width: 300px;
  }
}

/* Fade in left */


@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-00px);
    transform: translateX(-30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-30px);
    -ms-transform: translateX(-30px);
    transform: translateX(-30px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}




/* Fade in up */

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

/* Animations */


@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(40px);
    -ms-transform: translateX(40px);
    transform: translateX(40px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    -ms-transform: translateX(2000px);
    transform: translateX(2000px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
}

.fadeInRightBig {
  -webkit-animation-name: fadeInRightBig;
  animation-name: fadeInRightBig;
}

/* Ends Animations */


/* Custom */

.bb-1 {
  border-bottom: 1px solid rgb(206, 206, 206);
}

.text-left {
  text-align: left !important;
}

.bg-light-gray {
  background-color: #f5f5f5;
}

.border-left-blue {
  border-left: 5px solid #3652D9;
}

.text-align-right {
  text-align: right !important;
}

.fs-12 {
  font-size: 12px;
}

.gray-light-text {
  color: #9e9e9e;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.trRow {
  border-bottom: 1px solid rgb(198, 198, 198);
  padding: 10px;
  margin: 10px;
}

.messageButton {
  background-color: #fff;
  border: 1px solid rgb(198, 198, 198);
  border-radius: 10px !important;
  padding: 10px;
}

.linkView {
  color: #000;
  text-decoration: underline !important;
}

.mw-auto {
  width: auto !important;
}

.container {
  min-width: 100% !important;
}

.bg-yellow-gradient {
  background: rgb(255, 156, 0);
  background: linear-gradient(132deg, rgba(255, 156, 0, 1) 0%, rgba(255, 237, 0, 0.9990371148459384) 96%);
}

.minHeight-100 {
  min-height: 100vh;
}

.w-80 {
  width: 80%;
}

.btn-warning {
  background: rgb(255, 156, 0);
  background: linear-gradient(132deg, rgba(255, 156, 0, 1) 0%, rgba(255, 237, 0, 0.9990371148459384) 96%);
}

.fs-xsm {
  font-size: 13px !important;
}

.btn {
  border: none;
}

.mw-50-responsive {
  width: 50%;
}

.bred {
  border: 1px solid red;
}

.h-700 {
  height: 700px !important;
}

.card-img-top {
  height: 300px;
  border-radius: 20px;
}

.card-plaquita {
  width: 270px;
}

.card {
  padding: 0.5em;
  height: 30em;
  border: none !important;
}

.hideScrollbar::-webkit-scrollbar {
  border: none;
}

.hideScrollbar::-webkit-scrollbar-thumb {
  background: rgba(189, 189, 189, 0.27);
  border-radius: 10px;
  border: 0.70px solid rgb(223, 223, 223);
}

.bg-dark {
  background-color: #000 !important;
}

.text-dark {
  color: #000 !important;
}

.btn-full-rounded {
  border-radius: 50px;
}

.my-6 {
  margin-top: 6em !important;
  margin-bottom: 6em !important;
}

table {
  width: 100% !important;
}

.table-container-h-50 {
  height: 200px;
}

.table-container-h-auto {
  height: auto;
}


.fade-div {
  animation: fadeIn 300ms;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.c-pointer {
  width: 50px;
  cursor: pointer;
}

.c-pointer:hover {
  animation: fadeIn 300ms;
  background-color: rgba(159, 159, 159, 0.153);
  border-radius: 5px;
}

.btn-primary {
  background-color: #078fff;
  border-color: #078fff;
  color: #fff;
}

.btn-danger {
  background-color: #9f0000;
  color: #fff;
}

/* Ends Custom */


/* M modal */

.m-modal {
  position: absolute;
  z-index: 1;
  top: 0;
  height: 100%;

  width: 100%;


  background-color: rgba(0, 0, 0, 0.362);

  display: flex;
  justify-content: center;
  align-items: center;
}

.m-container {
  width: 60%;
  height: 80%;

  display: flex;
  padding: 1em;
  border-radius: 10px;
}

.m-modal-body {
  margin-left: auto !important;
  height: auto !important;
  margin-right: auto !important;
}

/* Ends M Modal */
/*  */
/* Homepage updates 07-11-2022 */
.container-card {



  -webkit-box-shadow: 0px 10px 49px -31px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 49px -31px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 49px -31px rgba(0, 0, 0, 0.75);
  border-radius: 10px;
}

.img-feature {
  width: 300px;
}

.landing {

  display: flex;
  align-items: center;
  justify-content: center;


  background: rgb(0, 0, 0);
  background: linear-gradient(263deg, rgba(50, 38, 13, 0.484) 0%, rgba(255, 234, 3, 0.387) 96%), url("https://images.unsplash.com/photo-1548199973-03cce0bbc87b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1469&q=80");
  background-size: cover;
  background-position: center;
  min-height: 500px;
}

/* Ends Homepage updates 07-11-2022 */


.badge-gray {
  background-color: #efefef;
}


.h-screen {
  min-height: 100vh !important;
}




/* 15-01-2024 */

.bg-paw-dog {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2F_e6bc4398-63b1-48ed-9176-053d27e98eeb.jpg?alt=media&token=e8ec24fa-24c4-4bed-bf1a-ae0ca059ceb5");
  background-position: center;
  background-size: contain;
  width: 70%;
}

.paw-card {
  border-radius: 20px;
  background: #fff;
}

.paw-yearly {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fbg-yearly-2.png?alt=media&token=983d47cc-b8f8-4c6c-9cb4-eb3d59fdcae9");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  height: 200px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 2%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Agregar transición a transform y box-shadow */
}

.paw-yearly:hover {
  cursor: pointer;
  box-shadow: 0px 41px 37px -3px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  /* Mover hacia arriba al pasar el cursor */
}

.text-gray-400 {
  color: #C6C6C6;
}

.paw-monthly {
  background-image: url("https://firebasestorage.googleapis.com/v0/b/dslb-wsb-cdn-americas.appspot.com/o/idpets%2Fbg-monthly-2.png?alt=media&token=c5ae2ca6-8c30-4f19-a975-3633776d8cf9");
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  background-color: #FBFBFB;
  height: 200px;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  padding: 2%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  /* Agregar transición a transform y box-shadow */
}

.paw-monthly:hover {
  cursor: pointer;
  box-shadow: 0px 41px 37px -3px rgba(0, 0, 0, 0.1);
  transform: translateY(-10px);
  /* Mover hacia arriba al pasar el cursor */
}

.icon-pay {
  width: 50px;
  height: 50px;
}

.bb-1-yellow {
  border-bottom: 1px solid #FFDC00;
}

.w-50-responsive {
  width: 50%;
}


.card-plan {
  box-shadow: 0px 34px 72px 38px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 1em;
  border: 1px solid #8a8a8a;
}

.ul-addons {
  list-style-type: none;
}

.bg-success-2 {
  background-color: #38EE85;
}

.img-card-plan {
  width: 200px;
  border-radius: 50%;
}

.bg-color-plan-lite {
  background-color: #FBFBFB;
}

.line-through {
  text-decoration: line-through;
}

.border-2-primary {
  border: 2px solid #ffea00
}

.border-2-secondary {
  border: 2px solid #282828
}

/* Sweet alert */


.swal2-confirm {
  margin: 0.3em !important;
  border-radius: 50px !important;
  background: rgb(255, 156, 0) !important;
  background: linear-gradient(132deg, rgba(255, 156, 0, 1) 0%, rgba(255, 237, 0, 0.9990371148459384) 96%) !important;
}

.swal2-cancel {
  margin: 0.3em !important;
  border-radius: 50px !important;
}

.swal2-icon {
  margin-top: 1em !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.swal2-html-container {
  margin-top: 1em !important;
  margin-bottom: 1em !important;

  margin-left: auto !important;
  margin-right: auto !important;
}

.btn-secondary-2{
  background: #000 !important;
}


.swal2-popup{
  padding: 2em !important;
}


.container-center{
  height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-loading{
  width: 30px;
  height: 30px;
}